<template>
  <div>
    <div style="display:flex">
      <div class="left">
        <LeftController ref="leftRef"
                        @initList="initList" />
      </div>
      <div class="right">
        <!-- <div class="video_main">
          <video class="item"
                 id="video"
                 :src="url"
                 width="100%"
                 height="100%"
                 controlslist="nodownload"
                 controls></video>
        </div> -->
        <div class="right_bottom">

          <div class="btn">
            <div>
              <el-select v-model="typeValue"
                         class="select"
                         @change="checkSubject"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.value"
                           :value="item.value"
                           :label="item.name">
                </el-option>
              </el-select>

            </div>

          </div>
          <div class="video_list"
               ref="videoListRef">
            <div class="video"
                 v-for="item,index in filtList"
                 :key="item.url+'no'+index"
                 @click="clikItem(item.url,item.type_name)">
              <div class="pic">   
                <img :src="item.pic"
                     v-if="typeValue!=='video'&&typeValue!=='image'"
                     :style="{'backgroundImage': getBackgroundColorProperty(typeValue, index)}">
                     <img :src="item.url"
                     v-if="typeValue=='image'"
                     :style="{'backgroundImage': getBackgroundColorProperty(typeValue, index)}">
                <video :src="item.url"
                       v-if="typeValue=='video'"
                       disablePictureInPicture
                       width="100%"
                       height="100%"
                       :style="{'background':'#EEEEEE'}" />

              </div>
              <div class="name">
                {{item.filename}}
              </div>
              <div class="btn_wrap"
                   v-if="item.ext=='mp4'">
                <img src="@/assets/course/标签文字.png"
                     alt="">
                <!-- <img v-show="item.url==url&&isPlay"
                     src="@/assets/course/icon-正在播放.png"
                     alt=""> -->
              </div>
            </div>
            <div v-if="filtList.length==0"
                 class="noData">
              <img src="@/assets/course/noData.png"
                   alt="">
              <div class="text">暂无资源</div>
            </div>
          </div>
        </div>
        <!-- <div class="pagination">
          <pagination ref="pagination"
                      :get-data="getData"
                      :now-page.sync="page"  
                      :now-size.sync="size"
                      :total="total" />
        </div> -->
      </div>
    </div>
  </div>

</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import LeftController from '@/views/components/left_controller.vue'
import { backgColorProperty } from './colorProperty.js'

import { getCategory, getSubject, getSchoolList } from '@/api/lecture.js'
import { getStudentSubject } from '@/api/subject.js'
export default {

  data () {
    return {
      cateList: [],
      url: '',
      sourceCode: '',
      subjectList: [],
      backgColorProperty: backgColorProperty,
      subjectId: '',
      type: 1, //1专题讲解 2 知识点讲解
      total: 0,
      keyword: '',
      typeValue: "video",
      page: 1,
      size: 20,
      videoEle: undefined,
      isPlay: false,
      list: [],
      typeList: [{}, {}],
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),

    }
  },
  components: {
    TopCard,
    LeftController
  },
  watch: {
    page: {
      handler: function () {
        this.$refs.videoListRef.scrollTop = 0
      }
    },
    keyword: {
      handler: function () {
        this.$refs.videoListRef.scrollTop = 0
      }

    }
  },
  computed: {
    filtList () {
      var list = JSON.parse(JSON.stringify(this.list))
      if (this.keyword !== '') {
        list = this.list.filter(item => {
          if (item.title.indexOf(this.keyword) != -1) {
            return item
          }
        })
      }
      this.total = list.length
      return list.splice((this.page - 1) * this.size, this.page * this.size)
    }
  },

  mounted () {
    // this.initList()
    // this.$http({
    //   url: '/GetCard',
    //   method: 'get',

    // }).then(res => {
    //   // this.typeList = res.data.list
    // })

    this.$nextTick(() => {
      this.videoEle = document.getElementById('video')
      this.videoEle.addEventListener('play', () => {
        this.isPlay = true
      })
      this.videoEle.addEventListener('pause', () => {
        this.isPlay = false
      })
    })

  },
  methods: {
    async initList (id) {
      this.subjectId = id
      this.getType()
      this.getData()
    },
    getBackgroundColorProperty (typeValue, index) {
      if (!backgColorProperty[typeValue]) {
        console.error(`Type value ${typeValue} is not defined in backgColorProperty.`);
        return '';
      }
      const files = backgColorProperty[typeValue];
      const validIndex = index % files.length;
      return files[validIndex];
    },
    getType () {

      this.$http({
        url: '/api/v1/teaching/type',
        method: 'get',

      }).then(res => {
        this.typeList = res.data.list
      })

    },
    clikItem (url, type) {
      console.log('type', type);

      if (type == "视频"||type=="音频") {
        this.$router.push("/video?video=" + url)
      } else {
        window.open(url, '_brank')

      }
    },
    changeType (str) {
      if (str == this.type) {
        return
      } else {
        this.type = str
        this.page = 1
        // this.getData()
        this.getSubject()
      }
    },
    async getData (page, limit) {
      if (page) {
        this.page = page
      }
      if (limit) {
        this.size = limit
      }
      // if (this.sourceCode == '') return
      // let params = {
      //   sourceCode: this.sourceCode,
      //   videoCode: this.subjectId,
      //   target_type: this.type,
      //   keyword: this.keyword
      // }
      const { data } = await getSchoolList({ edu_school_id: this.userInfo.edu_school_id, subjectId: this.subjectId, type: this.typeValue })
      this.list = data.list
      this.$refs.videoListRef.scrollTop = 0
    },
    check (row) {
      this.sourceCode = row.sourceCode
      this.page = 1
      this.getSubject()
    },
    async getSubject () {
      let params = {
        sourceCode: this.sourceCode,
        target_type: this.type
      }
      const { data } = await getStudentSubject(params)
      this.subjectList = data.list
      if (this.subjectList.length == 0) {
        this.subjectId = 0
      }
      else if (this.subjectList.length > 0) {
        this.subjectId = this.subjectList[0].subject_id
      }

      this.getData()
    },
    checkSubject (item) {
      // this.subjectId = item.videoCode
      this.page = 1
      this.getData()
    },
    changevideo (url) {
      if (this.url == url) {
        if (this.videoEle.paused) {
          this.videoEle.play()
          this.isPlay = true
        } else {
          this.isPlay = false
          this.videoEle.pause()
        }

      } else {
        this.url = url
        this.$nextTick(() => {
          this.isPlay = true
          this.videoEle.play()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left_menu {
  background: #f2f9ff;
  // height: calc(100vh - 100rem);
  height: 100vh;
  width: 375rem;
  min-width: 160px;
  overflow: auto;
  overflow-x: hidden;
}
.menu_item {
  width: 100%;
  // height: 74rem;
  min-height: 24px;
  padding: 20rem;
  background: rgba(255, 255, 255, 0);
  box-shadow: inset 0px -1px 0px 0px rgba(229, 229, 229, 1);
  font-size: 24rem;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #666666;
  cursor: pointer;

  line-height: 34rem;
}
.cative {
  background: #fff;
  color: #2196f3;
}
.right {
  flex: 1;
  padding: 30rem 30rem 10rem;

  .right_bottom {
    padding: 20rem 30rem;
    margin-top: 30rem;
    .btn {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
  .video_main {
    width: 900rem;
    height: 500rem;
    background: #333;
    border-radius: 5px;
    margin: 0 auto;
  }
  .btn_item {
    font-size: 18rem;
    background: #fff;
    border: 1px solid #ccc;
    padding: 8rem 25rem;
    border-radius: 5rem;
    margin: 5rem 15rem;
    cursor: pointer;
  }
  .btn_item_active {
    color: #fff;
    background: #2196f3;
  }
  .search {
    // position: absolute;
    width: 31%;
    height: 44rem;
    // top: 3%;
    // right: 50rem;
    ::v-deep .el-input--medium .el-input__inner {
      height: 44rem;
      line-height: 44rem;
      border-radius: 27rem;
      font-size: 18rem;
      font-weight: 500;
      color: #999999;
      background: white;
    }
    ::v-deep .el-icon-search {
      margin-right: 20rem;
      font-size: 22rem;
    }
    ::v-deep .el-input__suffix {
      display: flex;
      align-items: center;
    }
  }
  .video_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10rem;
    margin-bottom: 5rem;
    height: calc(100vh - 240rem);
    // min-height: 220rem;
    overflow-y: auto;
    // justify-content: space-between;
    align-content: flex-start;
    .noData {
      margin-left: 751rem;
      margin-top: 250rem;
      .text {
        color: var(--light-999999, #999);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        margin-left: 12rem;
        margin-top: 28rem;
      }
    }
    .video {
      width: 280rem;
      height: 200rem;
      min-width: 100px;
      min-height: 90px;
      background: white;
      box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
      border-radius: 20rem;
      margin: 20rem 20rem 10rem 10rem;
      cursor: pointer;
      position: relative;
      .pic {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84%;
        height: 64%;
        margin: 20rem auto 0;
        border-radius: 5rem;
        overflow: hidden;

        img {
          margin-top: 10rem;
          margin-bottom: 22rem;
          width: 100%;
          height: 100%;
          background: #58b0fb;
          border-radius: 5rem;
          background-size: 100% 100%;
          //background-image: url(require("@/assets/course/视频默认.png"));
        }
        video {
          background-size: 90% 100%;
          margin-top: 10rem;
          margin-bottom: 22rem;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .name {
        font-size: 18rem;
        font-weight: bold;
        color: #333333;
        margin: 0 30rem;
        height: 56rem;
        line-height: 27rem;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .btn_wrap {
        position: absolute;
        bottom: 63rem;
        right: 26rem;
        border-radius: var(--radius-8_xs, 4px);
        background: rgba(0, 0, 0, 0.3);
        width: 32rem;
        height: 24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          // width: 50rem;
          // height: 50rem;
          opacity: 0.9;
        }
      }
    }
  }
  .select {
    margin: 0 5rem 0 20rem;
    ::v-deep .el-input--medium {
      font-size: 18rem;
    }
    ::v-deep .el-input--medium .el-input__icon {
      line-height: 42rem;
    }
    ::v-deep .el-input--medium .el-input__inner {
      height: 42rem;
      line-height: 42rem;
    }
  }
}
</style>